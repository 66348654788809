import React, { Fragment, useState } from "react";
import { AudioView } from "./courseViews/AudioView/AudioView";
import ButtonView from "./courseViews/ButtonView/ButtonView";
import DividerView from "./courseViews/DividerView/DividerView";
import GifView from "./courseViews/GifView/GifView";
import ImageView from "./courseViews/ImageView/ImageView";
import TextViews from "./courseViews/TextView/TextView";
import ToggleList from "./courseViews/ToggleListView/ToggleList";
import { VideoView } from "./courseViews/VideoView/VideoView";
import { FileView } from "./courseViews/FileView/FileView";
import LinkView from "./courseViews/LinkView/LinkView";
import EmbedView from "./courseViews/EmbedView/EmbedView";
import ContainerView from "./courseViews/ContainerView/ContainerView";
import ComicView from "./courseViews/ComicView/ComicView";
import MCQView from "./courseViews/MCQView/MCQView";
import FillView from "./courseViews/FillView/FillView";
import MatchView from "./courseViews/MatchView/MatchView";
import ResponseView from "./courseViews/ResponseView/ResponseView";
import GridView from "./courseViews/GridView/GridView";
import ListView from "./courseViews/ListView/ListView";
import DiscussionView from "./courseViews/DiscussionView/DiscussionView";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import NativeEmbedView from "./courseViews/NativeEmbedView/NativeEmbedView";
import ScormView from "./courseViews/ScormView/ScormView";
import CarosalBlockView from "./courseViews/CarosalBlockView/CarosalBlockView";
import SurveyMonkeyView from "./courseViews/SurveyMonkeyView/SurveyMonkeyViews";

const CourseBlock = ({
  blockDetails,
  courseDetails,
  completedModulesData,
  setmodalOpen,
  courseOne,
  setblockModOpen,
  blockUserActivities,
  CollapseBlocks,
  ExpandBlocks,
}) => {
  //let UserData = useSelector((state) => state.userLogin);
  //let isLoggedIn = UserData?.data?.accessToken;
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  //const { cid } = useQueryParams();
  const [highlightedContainers, setHighlightedContainers] = useState([]);

  const highlightParentContainers = (container) => {
    const parentContainerIds = container?.parentContainers || [];
    const highlighted = [];

    parentContainerIds.forEach((parentId) => {
      const foundModule = completedModulesData?.modules?.find(
        (module) => module.module_id === parentId,
      );
      if ((foundModule && foundModule.status !== "locked") || !foundModule) {
        highlighted.push(parentId);
      }
    });

    setHighlightedContainers(highlighted);
  };

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);
  const nonCarosalContainers = blockDetails.filter((block) => !block?.carousel_container);

  return (
    <div className="course-main-block">
      <Grid container spacing={3} columns={100}>
        {(isModerator !== -1 ? blockDetails : nonCarosalContainers)?.map((block, index) => (
          <Fragment key={index}>
            {block?.type === "text" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <TextViews
                  block={block}
                  setmodalOpen={setmodalOpen}
                  theme={block?.theme}
                  art={block?.art}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                  carousel={false}
                />
              </Grid>
            )}
            {block?.type === "button" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ButtonView
                  key={block}
                  button={block}
                  setmodalOpen={setmodalOpen}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "divider" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <DividerView
                  key={block}
                  divider={block}
                  theme={isLoggedIn ? "light" : "dark"}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                />
                {/* {block?.comments.length !== 0 && (
                  <CommentView comments={block.comments} />
                )} */}
              </Grid>
            )}
            {block?.type === "toggle_list" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ToggleList
                  toggle_list={block?.items}
                  blockId={block?._id}
                  theme={block.theme}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "image" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ImageView
                  image={block?.image}
                  key={block?.image}
                  theme={block.theme}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "audio" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <AudioView
                  audio={block?.provider}
                  key={block?.provider}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                  carousel={false}
                />
              </Grid>
            )}
            {block?.type === "video" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <VideoView
                  video={block?.provider}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "file" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <FileView
                  file={block?.file}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}

            {block?.type === "carousel_container" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <CarosalBlockView
                  container={block}
                  completedModulesData={completedModulesData}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                  ExpandBlocks={ExpandBlocks}
                  CollapseBlocks={CollapseBlocks}
                />
              </Grid>
            )}

            {block?.type === "link" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <LinkView
                  link={block}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "gif" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <GifView
                  gif={block?.gif}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "mcq" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <MCQView
                  mcq={block}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  mcqs={block.mcqs}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                  blockUserActivities={blockUserActivities}
                  showBlockActionToolbar={true}
                />
              </Grid>
            )}
            {block?.type === "survey-monkey" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <SurveyMonkeyView
                  block={block}
                  courseDetails={courseOne}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "fill" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <FillView
                  fill={block}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                  blockUserActivities={blockUserActivities}
                />
              </Grid>
            )}
            {block?.type === "match" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <MatchView
                  match={block}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                  blockUserActivities={blockUserActivities}
                />
              </Grid>
            )}
            {block?.type === "response" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ResponseView
                  response={block}
                  block={block}
                  courseDetails={courseOne}
                  courseOne={courseOne}
                  art={block?.art}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                  blockUserActivities={blockUserActivities}
                />
              </Grid>
            )}
            {block?.type === "grid" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <GridView
                  grid={block}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "list" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ListView
                  list={block}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "container" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ContainerView
                  container={block}
                  completedModulesData={completedModulesData}
                  courseDetails={courseOne}
                  courseDetail={blockDetails}
                  courseOne={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                  ishighlighted={highlightedContainers.includes(block?._id)}
                  highlightParentContainers={highlightParentContainers}
                />
              </Grid>
            )}

            {block?.type === "comic" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ComicView
                  text={block.text}
                  image={block.image}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "embed" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <EmbedView
                  embed={block}
                  courseDetails={courseOne}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {block?.type === "scorm" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <ScormView
                  scorm={block}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                  blockUserActivities={blockUserActivities}
                />
              </Grid>
            )}
            {block?.type === "native-embed" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <NativeEmbedView
                  block={block}
                  courseDetails={courseOne}
                  setblockModOpen={setblockModOpen}
                  setmodalOpen={setmodalOpen}
                />
              </Grid>
            )}
            {/* {block?.comments.length !== 0 && (
              <Grid item lg={block?.size?.width}>
                <CommentView
                  comments={block.comments}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                />
              </Grid>
            )} */}
            {block?.type === "discussion" && (
              <Grid item className="fullwidth" lg={block?.size?.width}>
                <DiscussionView
                  discussion={block}
                  courseDetails={courseOne}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  courseOne={courseOne}
                  setblockModOpen={setblockModOpen}
                />
              </Grid>
            )}
            {/* {block?.comments.length !== 0 && (
              <Grid item lg={block?.size?.width}>
                <CommentView comments={block.comments} />
              </Grid>
            )} */}
          </Fragment>
        ))}
      </Grid>
    </div>
  );
};

export default CourseBlock;

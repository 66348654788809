import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { Image } from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";

const SurveyMonkeyView = ({ block, courseDetails, setmodalOpen, setblockModOpen }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);

  const { completedModulesData } = useOutletContext();

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const embededCode = (url) => {
    if (block?.type === "survey-monkey") {
      url =
        url +
        "?uniqueId=" +
        user?.id +
        "&courseId=" +
        courseDetails?._id +
        "&blockId=" +
        block?._id +
        "&cohortInviteCode=" +
        completedModulesData?.cohortDetails?._id +
        "&positionLabel=" +
        block?.position_label?.replace(/ /g, "-");
    }
    return url;
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div
          className={`embed-view 
      ${block?.theme} ? ${block?.theme} : "postBgColor"}
    `}
          style={{
            background: `${block?.theme_type === "color" ? block?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <ArtView art={block?.art} />
          {isModerator !== -1 && isLoggedIn && isHovering && (
            <BlockActions
              blockId={block?._id}
              block={block}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}
          <div className="block-embed" style={{ minHeight: `${block.size.height}px` }}>
            {block?.is_required && <span className="required">*</span>}
            {block?.url && (
              <iframe src={embededCode(block?.url)} frameborder="0" title="survey-monkey"></iframe>
            )}
          </div>
          <style>
            {`
          .block-embed  iframe {
            height: ${block.size.height}px ;
            min-height: inherit;
          }
          `}
          </style>
        </div>
      )}
    </>
  );
};

export default SurveyMonkeyView;

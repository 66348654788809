import * as React from "react";
import { useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import "./header.scss";
import { Constants } from "../../config/Constants";
import PreLoginHeader from "./PreLoginHeader";
import PostLogin from "./PostLogin";
import { Box } from "@mui/system";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { ParagraphText } from "../Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import Search from "../EnrolledCourseNew/LandingPage/Search";

function ResponsiveAppBar({ isReviewer }) {
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);
  let navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <AppBar
        position="relative"
        color="transparent"
        sx={{
          boxShadow: "unset",
          backgroundColor: `${isLoggedIn ? "#f7f7f7" : "#1A1A1A"}`,
        }}
        className="headerMain"
      >
        <Container maxWidth="xl" sx={{ width: "97%" }}>
          <Toolbar disableGutters className="headerToolbar">
            <Box className="logoMain">
              <Typography
                variant="h6"
                noWrap
                component="a"
                className="headerImg"
                href="/"
                display={"flex"}
                alignItems={"center"}
              >
                <img src={`${Constants.imgPath}/logo.png`} alt="logo" className="logo" />
              </Typography>
              <Box className="fmText">FramerSpace</Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {!location.pathname.includes("/search") && <Search />}
            {isLoggedIn ? <PostLogin /> : <PreLoginHeader />}
          </Toolbar>
          {isReviewer && (
            <Box className="reviewerHeaderText">
              <Box
                className="reviewExitWrapper"
                onClick={() =>
                  navigate("/review", {
                    state: {
                      userData: location.state.userData,
                    },
                  })
                }
              >
                <KeyboardArrowLeftIcon style={{ color: "white" }} />
                <ParagraphText className={"exitText"}>Exit Review Mode</ParagraphText>
              </Box>
              <ParagraphText className={"reviewViewTextMain"}>
                You are Viewing in the Review Mode
              </ParagraphText>
            </Box>
          )}
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;

import { FormControl, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { Dropdown } from "../../../../InputBox/InputBox";

const AddFSForm = ({
  assessmentSettings,
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [assessment, setAssessment] = useState("");
  return (
    <div>
      <FormControl
        disabled={!assessmentSettings?.isAdvanceAssessment}
        sx={{ paddingBottom: "10px", width: "100%" }}
        variant="standard"
      >
        <Dropdown
          onChange={(event) => setAssessment(event.target.value)}
          value={assessment}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <span className="dropdownLabel">Select a Domain</span>;
            } else {
              let selectedDomain = assessmentSettings?.assessmentDomain?.find(
                (domain) => domain?._id === selected,
              );
              return <span>{selectedDomain?.title}</span>;
            }
          }}
        >
          {assessmentSettings?.assessmentDomain?.length > 0 &&
            assessmentSettings?.assessmentDomain?.map((item, i) => {
              return (
                <MenuItem value={item?._id} key={i}>
                  {item?.title}
                </MenuItem>
              );
            })}
        </Dropdown>
      </FormControl>
    </div>
  );
};

export default AddFSForm;

import { Box, Grid, IconButton, Paper, Typography, Tooltip } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import { saveModuleDetailAction, setEntrySourceAction } from "../../../../redux/action";
import { isLearnerView, useQueryParams } from "../../../../utils";
import BlockActions from "../BlockActions/BlockActions";
import "./ContainerView.scss";
import ParentSvg from "../../../../assets/Parent_node.svg";
import BlockIcon from "@mui/icons-material/Block";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const ContainerView = ({
  container,
  courseDetails,
  courseDetail,
  setmodalOpen,
  setblockModOpen,
  block,
  ishighlighted,
  highlightParentContainers,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [childContainers, setchildContainers] = useState([]);

  const location = useLocation();
  const course = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  const courseDetailfromRedux = useSelector((state) => state.savedCourseDetail);
  const moduleDetails = useSelector((state) => state.savedModuleDetail);

  const { courseOne, completedModulesData, userDetails } = useOutletContext();

  const { cid } = useQueryParams();

  useEffect(() => {
    if (container?.parentContainers?.length > 0 && isParentLocked) {
      highlightParentContainers(container);
    }
  }, [completedModulesData]);

  useEffect(() => {
    fetchAllChildContainers();
  }, [courseDetail]);

  const handleClick = () => {
    let moduleDetails = {
      courseTitle: courseDetailfromRedux?.data?.title,
      containerTitle: container?.title,
      containerText: container?.text,
      id: container?._id,
      slug: container?.slug,
    };
    dispatch(saveModuleDetailAction(moduleDetails));
    if (
      location.pathname === "/course/createblock" ||
      location.pathname === "/course/createblock/createcontainer"
    ) {
      navigate(`/course/createblock/createcontainer?cid=${cid}&mid=${container?._id}`, {
        state: courseDetails,
      });
    } else {
      navigate(
        `/course/${course?.slug || courseDetails?.slug}/${container?.slug}?cid=${cid}&mid=${
          container?._id
        }`,
        { state: { userData: userDetails } },
      );
    }
  };

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  let isLocked = completedModulesData?.modules.some((item) => {
    if (item.module_id === container?._id && item.status === "locked") {
      return true;
    }
    return false;
  });

  const isParentLocked = container?.parentContainers
    ? container?.parentContainers?.every((parentContainerId) => {
        const parentModule = completedModulesData?.modules.find(
          (module) => module?.module_id === parentContainerId && module?.status === "locked",
        );
        return !!parentModule;
      })
    : true;

  const handleContainerClick = (e) => {
    e.stopPropagation();
    isLoggedIn && dispatch(setEntrySourceAction("module_clicked"));

    triggerUserEvent("module_clicked");

    if (!(container?.parentContainers?.length > 0 && !isParentLocked) || isModerator !== -1) {
      handleClick();
    } else {
      highlightParentContainers(container);
    }
  };

  const triggerUserEvent = (event) => {
    if (user?.email && container?.title && isLearnerView(location.pathname)) {
      const eventData = new clickEvent();
      eventData.event = event;
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails?.data?.id;
      eventData.block_id = container?._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.block_title = container?.title;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_type = container?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  const getContainerTitleById = (id) => {
    const container = courseDetail?.find((detail) => detail._id === id);
    return container ? container.title : id;
  };

  const fetchAllChildContainers = async () => {
    const childContainersData = [];
    // Iterate through each container and fetch its child containers
    await Promise.all(
      courseDetail?.map(async (container) => {
        const children = await getChildContainers(container?._id);
        childContainersData[container._id] = children;
      }),
    );
    setchildContainers(childContainersData);
  };

  const getChildContainers = async (containerId) => {
    return courseDetail?.filter((detail) => detail?.parentContainers?.includes(containerId));
  };

  return (
    <>
      {isModerator === -1 && block?.is_hidden ? (
        ""
      ) : (
        <div className={`${ishighlighted ? "highlightModule" : ""}`}>
          <Paper
            id="paper"
            className={container?.theme ? `${container?.theme} ` : "paperLight"}
            style={{
              background: `${container?.theme_type === "color" ? container?.color : ""}`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
            elevation={3}
          >
            {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
              <BlockActions
                blockId={container?._id}
                block={container}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
                childContainers={childContainers[container._id]}
              />
            )}

            <Box onClick={handleContainerClick} position={"relative"}>
              {isModerator === -1 && container?.parentContainers?.length > 0 && !isParentLocked && (
                <Box
                  position={"absolute"}
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  bgcolor={"rgb(245 244 244 / 80%)"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      p: { xs: 2, sm: 4 },
                      mt: { xs: "6rem", sm: "5rem" },
                      mx: { xs: "1rem", sm: "9rem" },
                      //textAlign: "center",
                    }}
                  >
                    <IconButton color="#fff">
                      <BlockIcon fontSize="large" />
                    </IconButton>
                    <Box>
                      <Typography fontSize="1.3rem">This module is not yet enabled</Typography>
                      <Typography fontSize="1.3rem">
                        Please attempt and complete the other modules to enable this module
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {isModerator === -1 &&
                container?.parentContainers?.length > 0 &&
                !isParentLocked &&
                !isLocked && (
                  <Box
                    position={"absolute"}
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    bgcolor={"rgb(245 244 244 / 80%)"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        p: { xs: 2, sm: 4 },
                        mt: { xs: "6rem", sm: "5rem" },
                        mx: { xs: "1rem", sm: "9rem" },
                      }}
                    >
                      <IconButton color="#fff">
                        <BlockIcon fontSize="large" />
                      </IconButton>
                      <Box>
                        <Typography fontSize="1.3rem">This module is not yet enabled</Typography>
                        <Typography fontSize="1.3rem">
                          Please attempt and complete the other modules to enable this module
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}

              {isMobile && (
                <MenuIconWrapper onClick={handleMenuClick}>
                  <MoreVertIcon fontSize="large" color="action" />
                </MenuIconWrapper>
              )}

              {isModerator !== -1 &&
                (container?.parentContainers?.length > 0 ||
                  childContainers[container?._id]?.length > 0) && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 1,
                    }}
                  >
                    <Tooltip
                      title={
                        <Box sx={{ p: 1, minWidth: "250px" }}>
                          {/* Display Parent Modules */}

                          {container?.parentContainers.length > 0 && (
                            <Box>
                              <Typography variant="subtitle1" gutterBottom>
                                Predecessor:{" "}
                                {container?.parentContainers.map((parentId, index) => (
                                  <span key={parentId}>
                                    {getContainerTitleById(parentId)}
                                    {index !== container?.parentContainers.length - 1 && ", "}
                                  </span>
                                ))}
                              </Typography>
                            </Box>
                          )}

                          {/* Display Child Modules */}
                          {childContainers[container._id]?.length > 0 && (
                            <Box>
                              <Typography variant="subtitle1" gutterBottom>
                                Successor:{" "}
                                {childContainers[container._id].map((child, index) => (
                                  <span key={child?._id}>
                                    {child.title}
                                    {index !== childContainers[container._id].length - 1 && ", "}
                                  </span>
                                ))}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      }
                      enterDelay={200}
                      leaveDelay={200}
                    >
                      <Box
                        //bgcolor={"#059669"}
                        borderRadius={"50%"}
                        height={"35px"}
                        width={"35px"}
                        sx={{
                          backgroundColor: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={ParentSvg}
                          style={{ objectFit: "contain" }}
                          alt="Predecessor & Successor"
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              {isLocked && isParentLocked && (
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick();
                  }}
                  position={"absolute"}
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  // sx={{backdropFilter:"blur(2px)"}}
                  bgcolor={"rgba(255, 255, 255,0.5)"}
                >
                  <Box
                    bgcolor={"#059669"}
                    position={"absolute"}
                    p={1}
                    m={1}
                    borderRadius={"50%"}
                    top={0}
                    left={0}
                    height={"40px"}
                    width={"40px"}
                    // color={"black"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#fff"
                      style={{ height: "24px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                  </Box>
                </Box>
              )}

              <Grid container spacing={2} className="grid-container-main">
                <Grid item xs={12} sm={5} md={4} lg={3} className="module-img-container">
                  {container?.image && (
                    <img
                      src={
                        container?.image?.m?.startsWith(cloudfrontUrl)
                          ? container?.image?.m
                          : cloudfrontUrl + container?.image?.m
                      }
                      alt={container?.title}
                      className="course-img"
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={9} className="grid-container">
                  {container?.title !== "" && (
                    <div className="module-title">{container?.title}</div>
                  )}
                  {container?.text && (
                    <p className="module-text">
                      {container?.text?.length <= 300 ? (
                        <>{container?.text?.replace(/<[^>]+>/g, " ")}</>
                      ) : (
                        <>
                          {container?.text?.replace(/<[^>]+>/g, "").substr(0, 300)}
                          <strong className="read-more">...Read more.</strong>
                        </>
                      )}
                    </p>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </div>
      )}
    </>
  );
};

export default ContainerView;
